<template>
  <div id="LoanIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuName" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.xn +'年'}}{{ codeConvertLabel(row.xq,dict.xj_xqgl) }}</span></span>
          </template>
          <template #label>
            <div class="custom-centent hidden-text" style="width:95%;margin-top:7px">
              <svg-icon icon-class="date" size="15" @click="toStuDeatil" /> 姓名: {{row.stuName +"("+row.stuNo+")"}}
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="apps-o" size="15" /> 班级: {{row.className}}
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="points" size="15" /> 考试方式: {{row.examNatureName  }}
            </div>
          </template>
          <template #right-icon>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <div style="line-height:35px"><b>{{row.couName}}</b></div>
          </template>
          <template #right-icon>
            <span class="table-detail-btn " @click="toDetail(row)" style="color:#B2B2B2">
              成绩: <span style="color:#23AB72;font-weight:bold">{{row.couMark  }}</span> <span style="margin-left:10px">学分: <span style="color:#23AB72;font-weight:bold">{{row.creditScore}}</span></span>
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>
<script>

import initData from "@/mixins/initData";

export default {
  name: "LoanIndex",
  mixins: [initData],
  dicts: ["xj_xqgl", "xj_nj"],
  data() {
    return {
    };
  },
  created() {

  },
  methods: {
    beforeInit() {
      this.url = "api/evaluation/stuScore";
      this.sort = ['collNo,asc', 'specCode,asc', 'classNo,asc', 'stuName,asc', 'xq,desc', 'xn,desc'];
      return true;
    },
    toDetail(data) {
      this.$router.push({
        path: "/xc/achievement/detail",
        query: { id: data.id },
      });
    },
    toStuDeatil(data) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(data.stuNo) },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
</style>